/* eslint-disable no-underscore-dangle */
import { IResource } from '../../Types/Utils/Resource';
import JsonDocument from '../Documents/JsonDocument';

const _document = Symbol('document');

type TData = {
  id: string;
  type: string;
};

export default class Resource implements IResource {
  public id: string;

  public resourceType: string;

  protected [_document]: JsonDocument;

  constructor(item: TData, document: JsonDocument) {
    this.id = item.id;
    this.resourceType = item.type;
    this[_document] = document;
  }
}
