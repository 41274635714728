import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IResource } from '../Types/Utils/Resource';

export function useDestroy<Resource extends IResource, DestroyInput = void>(
  request: ApiRequest,
  queryConfig?: UseMutationOptions<
    DataDocument<Resource>,
    ErrorDocument,
    DestroyInput,
    any
  >
) {
  const queryClient = useQueryClient();
  return useMutation<DataDocument<Resource>, ErrorDocument, DestroyInput, any>({
    mutationFn: request.destroy.bind(request),
    mutationKey: request.getKey('destroy') || '',
    onSuccess: () =>
      Promise.all<void>([
        queryClient.invalidateQueries({
          queryKey: [{ [request.baseName]: true }],
        }),
        ...request.invalidate.map((invalidKey) =>
          queryClient.invalidateQueries({
            queryKey: [{ [invalidKey]: true }],
          })
        ),
      ]).then(),
    ...queryConfig,
  });
}
