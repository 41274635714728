import React, { PropsWithChildren, ReactNode } from 'react';
import { Accordion } from '@brainstud/ui/Static/Accordion';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import styles from './NavigationGroup.module.css';

const classes = classNames.bind(styles);

type Props = {
  pathname: string | string[];
  title: string;
  icon?: ReactNode;
};

/**
 * NavigationGroup.
 *
 *
 */
export const NavigationGroup = ({
  pathname: currentPathname,
  icon: Icon,
  title,
  children,
}: PropsWithChildren<Props>) => {
  const { pathname } = useRouter();
  const isCurrentPath = Array.isArray(currentPathname)
    ? currentPathname.some((item) => pathname.startsWith(item))
    : pathname.startsWith(currentPathname);
  return (
    <Accordion
      open={isCurrentPath}
      quiet
      heading
      className={classes(styles.base, {
        'has-children-active': isCurrentPath,
      })}
      title={
        <>
          {Icon || null}
          <span>{title}</span>
        </>
      }
    >
      <div className={styles.submenu}>{children}</div>
    </Accordion>
  );
};
