import React from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Button } from '@brainstud/ui/Buttons/Button';
import { ArrowForward } from '@mui/icons-material';
import { ReactComponent as Illustration } from 'Assets/privacy_image.svg';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { useTranslator } from 'Providers/Translator';
import styles from './PaymentRequiredView.module.css';

const cx = classNames.bind(styles);

/**
 * PaymentRequiredView
 *
 * A view to display when the payment is required by the user to access the features on this page
 */
export const PaymentRequiredView = () => {
  const [t] = useTranslator();
  const [me] = useMe();

  const loggedIn = me?.id !== 'me.unauthenticated';
  const canBuySubscription =
    me?.availableBusinessModels?.includes('subscription');

  return (
    <div className={cx(styles.base)}>
      <Container>
        <h1>
          {t(`views.status_page.402.title.${loggedIn ? 'user' : 'guest'}`)}
        </h1>
        <Illustration />
        <p className={cx(styles.description)}>
          {t(
            `views.status_page.402.description.${loggedIn ? 'user' : 'guest'}`
          )}
        </p>
        <div className={cx(styles.buttons)}>
          {!loggedIn && (
            <>
              <Button outline type="button" to="/auth">
                <span>{t('views.status_page.402.login')}</span>
              </Button>
              <Button type="button" to="/auth/login/register">
                <span>{t('views.status_page.402.try_free')}</span>
              </Button>
            </>
          )}
          {loggedIn &&
            (canBuySubscription ? (
              <Button
                type="button"
                className={cx(styles.seperation)}
                to="/account/orders"
              >
                <span>{t('views.status_page.402.subscriptions')}</span>
                <ArrowForward />
              </Button>
            ) : (
              <Button type="button" className={cx(styles.seperation)} to="/">
                <span>{t('views.status_page.402.homepage')}</span>
                <ArrowForward />
              </Button>
            ))}
        </div>
      </Container>
    </div>
  );
};
export default PaymentRequiredView;
