import { useIndex } from '../Factories/useIndex';
import { useShow } from '../Factories/useShow';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Portal,
  PortalFilters,
  PortalIncludes,
  PortalMeta,
  PortalSort,
} from '../Types/Resources/Portal';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface IPortalProps
  extends HookParams<
    typeof PortalIncludes,
    PortalFilters,
    PortalSort,
    PortalMeta
  > {
  portal?: UUID;
}

export function usePortal(
  { portal, ...queryParameters }: IPortalProps = {},
  settings?: IUseQueryOptions<DataDocument<Portal>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals',
      baseUri: '/v1/portals',
      invalidate: ['portal', 'portals', 'v1.accounts.portals'],
      uri: `/${portal || defaultPortal}`,
      queryParameters,
    },
    context
  );
  return useShow<Portal>(request, settings);
}

export function usePortals(
  { ...queryParameters }: Omit<IPortalProps, 'portal'> = {},
  settings?: IUseQueryOptions<DataDocument<Portal[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.portals',
      baseUri: '/v1/portals',
      invalidate: ['order', 'portals', 'v1.accounts.portals'],
      queryParameters,
    },
    context
  );
  return useIndex<Portal>(request, settings);
}
