import { AxiosResponse } from 'axios';
import { IResourceOrCollection } from '../../../Types/Utils/Resource';
/* eslint-disable-next-line import/no-cycle */
import JsonResource from '../../Resource/JsonResource/JsonResource';
import JsonDocument from '../JsonDocument';
import { TResourceLink, TResourceObject } from '../types';

type TAxiosApiResponse = {
  data: TResourceObject | TResourceObject[];
  included?: TResourceObject[];
};

export default class DataDocument<
  Resource extends IResourceOrCollection = any,
> extends JsonDocument {
  public data: Resource;

  public included: TResourceObject[] = [];

  public skipPropertyConversion: string[] = [];

  constructor(
    response: Omit<AxiosResponse<TAxiosApiResponse>, 'headers' | 'config'>,
    skipPropertyConversion: string[] = []
  ) {
    super(response);
    if (typeof response.data?.data === 'object' && 'data' in response.data) {
      this.included = response.data.included || [];
      this.skipPropertyConversion = skipPropertyConversion;

      const { data } = response.data;
      if (Array.isArray(data)) {
        this.data = data.map(
          (dataItem) => new JsonResource(dataItem, this)
        ) as unknown as Resource;
      } else {
        this.data = new JsonResource(data, this) as unknown as Resource;
      }
    } else {
      this.data = response.data?.data as unknown as Resource;
    }
  }

  findByTypeAndId(type: string, id: string): Resource | undefined {
    const data = this.included.find(
      (item) => item.id === id && item.type === type
    );
    return data
      ? (new JsonResource(data, this) as unknown as Resource)
      : undefined;
  }

  findByType<I>(type: string): I[] {
    return (
      this.included
        ?.filter((item) => item.type === type)
        .map((item) => new JsonResource(item, this) as unknown as I) || []
    );
  }

  findByResourceLink(
    resourceLink?: TResourceLink | TResourceLink[]
  ): undefined | Resource | Resource[] {
    if (!resourceLink) return;

    if (Array.isArray(resourceLink)) {
      return resourceLink
        .map((relation) => this.findByResourceLink(relation))
        .filter((n) => !!n) as Resource[];
    }
    return this.findByTypeAndId(resourceLink.type, resourceLink.id);
  }
}
