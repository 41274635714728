import React, { ReactNode, useMemo } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { AccessContext } from './AccessContext';

type Props = {
  children?: ReactNode;
};

export const AccessProvider = ({ children }: Props) => {
  const [me] = useMe();

  // check if the current BusinessModel requires a subscription
  const isSubscriptionRequired =
    !!me &&
    !me?.availableBusinessModels?.includes('flat_fee') &&
    (me.availableBusinessModels?.includes('subscription') ||
      me.availableBusinessModels?.includes('in_plan'));

  const isSubscriptionActive = !!me?.subscription?.().isActive;

  const hasAccess = isSubscriptionActive || !isSubscriptionRequired;

  const context = useMemo(
    () => ({
      hasAccess,
      isSubscriptionRequired: !!isSubscriptionRequired,
      isSubscriptionActive,
      subscriptionPromotionAvailable: !!me?.subscriptionPromotionAvailable,
    }),
    [
      hasAccess,
      isSubscriptionActive,
      isSubscriptionRequired,
      me?.subscriptionPromotionAvailable,
    ]
  );

  return (
    <AccessContext.Provider value={context}>{children}</AccessContext.Provider>
  );
};
