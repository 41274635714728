import React, { useCallback, useMemo, useState } from 'react';
import { withoutTrailingSlash } from '@brainstud/academy-api/Utils/trailingSlash';
import { QueryClientProvider } from '@tanstack/react-query';
import { API_BASE_PATH, DEFAULT_INCLUDES } from 'Config/api';
import ApiContext from './ApiContext';
import type {
  DefaultIncludes,
  DefaultRouteParameters,
  IApiContext,
  THeaders,
} from './IApiContext';
import queryClient from './QueryClient';
import useAxiosDefaults from './useAxiosDefaults';

type Props = {
  headers?: THeaders;
  children: JSX.Element | JSX.Element[];
};

const ApiProvider = ({ headers: defaultHeaders, children }: Props) => {
  const baseUrl = withoutTrailingSlash(API_BASE_PATH);

  const [includes, setIncludes] = useState<DefaultIncludes>(DEFAULT_INCLUDES);
  const [routeParameters, setRouteParameters] =
    useState<DefaultRouteParameters>({});
  const [headers, setHeaders] = useState({
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...defaultHeaders,
  });

  useAxiosDefaults(baseUrl, headers);

  const setHeader = useCallback(
    (name: string, value: string) =>
      setHeaders((prevHeaders) => ({ ...{ [name]: value }, ...prevHeaders })),
    []
  );

  const data = useMemo(
    () => ({
      parameters: routeParameters,
      includes,
      headers,
      baseUrl,
    }),
    [baseUrl, includes, headers, routeParameters]
  );

  const invalidateQueries = useCallback(
    (names: string[]) =>
      names.map((name) =>
        queryClient.invalidateQueries({ queryKey: [{ [name]: true }] })
      ),
    []
  );

  const methods = useMemo(
    () => ({
      setRouteParameters,
      setIncludes,
      setHeader,
      invalidateQueries,
    }),
    [setRouteParameters, setHeader, invalidateQueries]
  );

  const state: IApiContext = useMemo(
    () => ({
      ...data,
      ...methods,
    }),
    [data, methods]
  );

  return (
    <ApiContext.Provider value={state}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ApiContext.Provider>
  );
};

export default ApiProvider;
