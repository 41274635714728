import React, { ReactNode, useEffect, useState } from 'react';
import { Loading } from 'Components/Loading';
import { useRouter } from 'next/router';

type TProps = {
  error?: unknown;
  handleReset: () => void;
  children: ReactNode;
};

/**
 * ErrorTransition.
 *
 * A rather complex, but effective way  to reset the error on route change. In the past, we added a `key` prop on the
 * ErrorBoundary, however, that would re-render the entire tree every time the route changed, making it very unstable
 * application. This will execute `handleReset` during the route change, without re-rendering the component that had
 * an error (however briefly).
 */
export const ErrorTransition = ({ error, handleReset, children }: TProps) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (handleReset && error) {
      const handleStartReset = () => {
        setLoading(true);
        handleReset();
      };

      router.events.on('routeChangeStart', handleStartReset);
      return () => {
        router.events.off('routeChangeStart', handleStartReset);
      };
    }
  }, [error, handleReset, router.events]);

  useEffect(() => {
    if (loading) {
      const handleFinishReset = () => {
        setLoading(false);
      };
      router.events.on('routeChangeComplete', handleFinishReset);
      return () => {
        router.events.off('routeChangeComplete', handleFinishReset);
      };
    }
  }, [loading, router.events]);

  return loading ? <Loading /> : <>{children}</>;
};
