import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT =
  process.env.APP_ENV || process.env.NEXT_PUBLIC_APP_ENV;

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate:
    {
      development: 0,
      review: 0,
      staging: 0,
      production: 0.01,
    }[SENTRY_ENVIRONMENT] || 0,
  environment: SENTRY_ENVIRONMENT,
  ignoreErrors: [
    // Caused event overload and is harmless:
    // https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
});
