import {
  QueryObserverResult,
  useQuery,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { IResource } from '../Types/Utils/Resource';

type Output<Resource extends IResource> = [
  Resource | undefined,
  (
    | QueryObserverResult<DataDocument<Resource>, ErrorDocument>
    | UseSuspenseQueryResult<DataDocument<Resource>, ErrorDocument>
  ),
];

export function useShow<Resource extends IResource>(
  request: ApiRequest | null,
  queryConfig?: IUseQueryOptions<DataDocument<Resource>, ErrorDocument>
): Output<Resource> {
  const queryKey = request?.getKey('show') || [];
  const queryHook = queryConfig?.suspense ? useSuspenseQuery : useQuery;
  const response = queryHook<DataDocument<Resource>, ErrorDocument>({
    queryKey,
    queryFn: request?.show.bind(request),
    ...queryConfig,
    enabled:
      !!request &&
      !!request.enabled &&
      queryConfig?.enabled !== false &&
      request.isRouteValid(),
  });

  return [response?.data?.data, response];
}
