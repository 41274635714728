import React, { ReactNode } from 'react';
import { useEventHandler } from 'Hooks/EventHandler/useEventHandler';
import { SystemEventContext } from './SystemEventContext';

type Props = {
  children: ReactNode;
};

/**
 * SystemEventProvider.
 *
 * Emit & subscribe to events on a system-wide level to enable events on 'unmount' of the original component.
 */
export const SystemEventProvider = ({ children }: Props) => {
  const context = useEventHandler();

  return (
    <SystemEventContext.Provider value={context}>
      {children}
    </SystemEventContext.Provider>
  );
};
