import { CookieSerializeOptions, serialize } from 'cookie';
import { ServerResponse } from 'http';
import { NextApiRequest, NextApiResponse } from 'next';
import { getRootDomain } from 'Utils/getRootDomain';
import { DEFAULT_COOKIE_OPTIONS } from '../Config/cookies';
import { arrayWrap } from './arrayWrap';

type TCookie = {
  name: string;
  value: string;
  options?: CookieSerializeOptions;
};

/**
 * Make sure `.unlimited.software` is used in staging and review apps for cookie domains.
 */
function checkForStagingCookieDomain(
  domain: string | undefined
): string | undefined {
  if (['staging', 'review'].includes(process.env.NEXT_PUBLIC_APP_ENV || '')) {
    return '.unlimited.software';
  }
  return domain;
}

/**
 * setCookie.
 *
 * Set a cookie on the backend.
 *
 * @param res
 * @param cookies
 */
export function setCookies(
  res: ServerResponse | NextApiResponse,
  cookies: Array<TCookie> = []
): void {
  const domain = checkForStagingCookieDomain(
    getRootDomain(res.req.headers.host)
  );
  const defaultOptions = {
    ...DEFAULT_COOKIE_OPTIONS,
    domain,
  };

  const existingCookies = arrayWrap(res.getHeader('Set-Cookie')) as string[];

  const mergedCookies = [
    ...existingCookies,
    ...cookies.map(({ name, value, options }) => {
      const stringValue =
        typeof value === 'object' ? JSON.stringify(value) : String(value);
      return serialize(name, stringValue, {
        ...defaultOptions,
        ...options,
      });
    }),
  ];

  res.setHeader('Set-Cookie', mergedCookies);
}

/**
 * setBrowserCookie.
 *
 * Sets a cookie on the browser side
 */
export function setBrowserCookie(
  cName: string,
  cValue: string | number,
  daysValid: number = 1
) {
  const date = new Date();
  date.setTime(date.getTime() + daysValid * 24 * 60 * 60 * 1000);
  document.cookie = `${cName}=${cValue}; expires=${date.toUTCString()}; path=/`;
}

/**
 * removeBrowserCookie.
 *
 * Removes a browser cookie
 */
export function removeBrowserCookie(name: string) {
  if (typeof document !== 'undefined') {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
  }
}

/**
 * getBrowserCookie.
 *
 * Get browser cookie by name
 */
export function getBrowserCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift();
}

/**
 * getDevCookieSafely.
 *
 * Helper function to retrieve the current configured api endpoint. For easy debugging, this is made
 * dynamic.
 */
export function getDevCookieSafely(
  name: string,
  req: Pick<NextApiRequest, 'cookies' | 'headers'>,
  fallback?: string
) {
  if (
    process.env.APP_ENV === 'development' ||
    process.env.APP_ENV === 'review' ||
    process.env.APP_ENV === 'staging'
  ) {
    if (req?.cookies[name]) {
      return req?.cookies[name];
    }
  }
  return fallback;
}
