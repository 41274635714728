import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'Hooks/BrowserStorage/useLocalStorage';
import { IPaginationContext, PaginationContext } from './PaginationContext';

type Props = {
  /** Set the starting page: default 1 */
  startingPage?: IPaginationContext['page'];
  /** Set the starting limit: default 25 */
  startingLimit?: IPaginationContext['limit'];
  /** If provided, it will store the filter settings in the local storage of the browser. */
  storageKey?: string;
};

/**
 * PaginationProvider.
 *
 * A PaginationProvider tracks the state of paginated content by providing data about the
 * filter, sorting, page, and limit for the paginated dataset.
 */
export const PaginationProvider = ({
  children,
  startingPage = 1,
  startingLimit = 25,
  storageKey,
}: PropsWithChildren<Props>) => {
  const [filter, setFilter] =
    useLocalStorage<IPaginationContext['filter']>(storageKey);
  const [sort, setSort] = useState<IPaginationContext['sort']>();
  const [page, setPage] = useState<IPaginationContext['page']>(startingPage);
  const [limit, setLimit] =
    useState<IPaginationContext['limit']>(startingLimit);

  const toggleSortBy = useCallback((sortBy: string) => {
    setSort((prevSort) => {
      if (prevSort) {
        if (prevSort.includes(`-${sortBy}`)) {
          const sortArray = prevSort.filter((item) => item !== `-${sortBy}`);
          return sortArray.length ? sortArray : undefined;
        }
        if (prevSort.includes(sortBy)) {
          return [`-${sortBy}`, ...prevSort.filter((item) => item !== sortBy)];
        }
        return [sortBy, ...prevSort];
      }
      return [sortBy];
    });
  }, []);

  const setStringFilter = useCallback(
    (filterName: string, value: null | string) => {
      setFilter((prevFilters) =>
        value
          ? { ...prevFilters, [filterName]: value }
          : Object.keys(prevFilters || {}).reduce(
              (output, item) => ({
                ...output,
                ...(item !== filterName && prevFilters
                  ? { [item]: prevFilters[item] }
                  : {}),
              }),
              {}
            )
      );
    },
    [setFilter]
  );

  const state = useMemo(
    () => ({
      filter: filter || undefined,
      sort,
      page,
      limit,
      setFilter,
      setSort,
      setPage,
      setLimit,
      toggleSortBy,
      setStringFilter,
    }),
    [filter, sort, page, limit, setFilter, toggleSortBy, setStringFilter]
  );

  return (
    <PaginationContext.Provider value={state}>
      {children}
    </PaginationContext.Provider>
  );
};
