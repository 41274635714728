import React, { ReactNode, useCallback, useMemo } from 'react';
import { useAccountPreferences } from '@brainstud/academy-api/Hooks/useAccountPreferences';
import { TourProvider } from '@reactour/tour';
import classNames from 'classnames/bind';
import isBefore from 'date-fns/isBefore';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from '../EnvironmentProvider';
import { OnboardingFeatures } from './Features';
import styles from './OnboardingProvider.module.css';

const classes = classNames.bind(styles);

type TSeenFeatures = {
  [key: string]: number;
};

type Props = {
  children: ReactNode;
};

const ONBOARDING_PROPERTY = 'onboarding-features' as const;
const SEPARATOR = ';' as const;

/**
 * OnboardingProvider.
 *
 * Provides the scaffolding for showing onboarding tooltips. It is based on the onboarding
 * property on the account preferences to track which onboarding should be shown and
 * which has been viewed already.
 */
export const OnboardingProvider = ({ children }: Props) => {
  const { pathname: path } = useRouter();
  const [{ data: onboarding, create: createOrUpdate }, { isLoading }] =
    useAccountPreferences();
  const { isGuestEnvironment, isLoggedIn } = useEnvironmentProvider();

  const seenFeatures = useMemo(() => {
    if (!isLoading) {
      const storedValue = onboarding.find(
        (item) => item.property === ONBOARDING_PROPERTY
      )?.payload;
      if (storedValue) {
        return (
          storedValue?.split(SEPARATOR).reduce<TSeenFeatures>(
            (output, value) => ({
              ...output,
              [value.split('@')[0]]: parseInt(value.split('@')[1], 10),
            }),
            {}
          ) || {}
        );
      }
      return {};
    }
    return undefined;
  }, [isLoading, onboarding]);

  const unseenFeatures = useMemo(() => {
    if (!seenFeatures) return [];

    return OnboardingFeatures.filter((feature) => {
      const isFeatureExpired =
        !feature.until || isBefore(feature.until, Date.now());
      const isFeatureForCurrentPath =
        !feature.uri ||
        (Array.isArray(feature.uri)
          ? feature.uri.includes(path)
          : feature.uri === path);
      const isFeatureNew =
        seenFeatures[feature.id] === undefined ||
        seenFeatures[feature.id] < (parseInt(`${feature.version}`, 10) || 0);

      return isFeatureExpired && isFeatureForCurrentPath && isFeatureNew;
    });
  }, [seenFeatures, path]);

  const allSteps = unseenFeatures.map((item) => item.steps).flat();

  const handleSaveProgress = useCallback(() => {
    createOrUpdate.mutate({
      property: ONBOARDING_PROPERTY,
      payload: OnboardingFeatures.reduce<string[]>(
        (output, feature) => [
          ...output,
          `${feature.id}@${feature.version || 0}`,
        ],
        []
      ).join(SEPARATOR),
    });
  }, [createOrUpdate]);

  return !isGuestEnvironment && isLoggedIn && allSteps.length > 0 ? (
    <TourProvider
      steps={allSteps}
      defaultOpen
      className={classes(styles.onboarding)}
      showNavigation={allSteps.length > 1}
      maskClassName={classes(styles.mask)}
      showBadge={false}
      onClickClose={handleSaveProgress}
    >
      {children}
    </TourProvider>
  ) : (
    <>{children}</>
  );
};
