import React, { FC, useCallback } from 'react';
import { usePagination } from '@brainstud/universal-components/Providers/PaginationProvider/usePagination';
import classNames from 'classnames/bind';
import dynamic from 'next/dynamic';
import { Button } from '../../Buttons/Button';
import { IPaginationProps } from './PaginationProps';
import styles from './Pagination.module.css';

const cx = classNames.bind(styles);

const ReactPaginate = dynamic(() => import('react-paginate'));

/**
 * The Pagination component depends on the PaginationProvider from the universal-components package.
 * Make sure it is loaded before using this component!
 */
export const Pagination: FC<IPaginationProps> = ({
  totalPages = 1,
  labels = {},
  limits = [25, 50, 75],
  totalResults,
  onPageChange,
}) => {
  const { page, limit, setPage, setLimit } = usePagination();

  const itemsPerPageLabel = labels.itemsPerPage || 'Results per page';

  const updateLimit = useCallback(
    (newLimit: number) => {
      setPage(1);
      setLimit(newLimit);
    },
    [setPage, setLimit]
  );

  type PageChangeHandler = ({ selected }: { selected: number }) => void;
  const handlePageChange = useCallback<PageChangeHandler>(
    ({ selected }) => {
      setPage(selected + 1);
      if (window.scroll) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      } else {
        window.scrollTo(0, 0);
      }
      onPageChange?.(selected);
    },
    [setPage, onPageChange]
  );

  return (
    <div className={cx(styles.pagination)}>
      <div className={cx(styles.limits)}>
        {`${itemsPerPageLabel}:`}
        <ul className={cx(styles['limit-options'])}>
          {limits.map((clickableLimit) => (
            <li
              key={clickableLimit}
              className={limit === clickableLimit ? styles.active : ''}
            >
              <Button link onClick={() => updateLimit(clickableLimit)}>
                {clickableLimit}
              </Button>
            </li>
          ))}
        </ul>
        {totalResults && (
          <span className={cx(styles['total-results'])}>{totalResults}</span>
        )}
      </div>
      <div className={cx(styles.pages)}>
        {totalPages > 1 && (
          <ReactPaginate
            forcePage={(page || 1) - 1}
            breakLabel="…"
            nextLabel={
              <svg
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
              </svg>
            }
            previousLabel={
              <svg
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
              </svg>
            }
            containerClassName={cx(styles['pagination-list'])}
            pageClassName={cx(styles['pagination-item'])}
            breakClassName={cx(styles['pagination-item'])}
            previousClassName={cx(styles['pagination-item'])}
            nextClassName={cx(styles['pagination-item'])}
            activeClassName={cx(styles.selected)}
            disabledClassName={cx(styles.disabled)}
            pageLinkClassName={cx(styles.pagelink)}
            breakLinkClassName={cx(styles.pagelink)}
            previousLinkClassName={cx(styles.pagelink)}
            nextLinkClassName={cx(styles.pagelink)}
            activeLinkClassName={cx(styles['active-pagelink'])}
            onPageChange={handlePageChange}
            pageRangeDisplayed={2}
            pageCount={totalPages || 1}
            marginPagesDisplayed={2}
          />
        )}
      </div>
    </div>
  );
};
