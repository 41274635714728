import React, { useCallback } from 'react';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { AccountBox, Person, VerifiedUser } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { ADMIN_ENV_ROLES, COACH_ROLES, STUDENT_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { useTranslator } from 'Providers/Translator';
import styles from './EnvironmentSwitch.module.css';

const cx = classNames.bind(styles);

/**
 * EnvironmentSwitch.
 *
 * A dropdown to switch between environments.
 */
export const EnvironmentSwitch = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const isAdmin = useHasAnyRole(ADMIN_ENV_ROLES);
  const isCoach = useHasAnyRole(COACH_ROLES);
  const isStudent = useHasAnyRole(STUDENT_ROLES);

  const { environment, isGuestEnvironment, baseEnvironment } =
    useEnvironmentProvider();

  const handleEnvironmentSwitch = useCallback(
    (value?: string) => {
      if (value) {
        router.push(value);
      }
    },
    [router]
  );

  const visibleEnvironment = !isGuestEnvironment
    ? environment
    : baseEnvironment;

  return (
    <Dropdown
      block
      label={t('layouts.navigation.environment_switch.label')}
      value={`/${visibleEnvironment === 'student' ? 'catalog' : visibleEnvironment}`}
      onChange={handleEnvironmentSwitch}
      className={cx(styles.base)}
    >
      {isAdmin && (
        <Dropdown.Option value="/admin">
          <div className={cx(styles.aligned)}>
            <VerifiedUser fontSize="large" className={cx(styles.icon)} />
            <span>{t('layouts.navigation.admin')}</span>
          </div>
        </Dropdown.Option>
      )}
      {isCoach && (
        <Dropdown.Option value="/coach">
          <div className={cx(styles.aligned)}>
            <AccountBox fontSize="large" className={cx(styles.icon)} />
            <span>{t('layouts.navigation.coach')}</span>
          </div>
        </Dropdown.Option>
      )}
      {(isStudent || isCoach) && (
        <Dropdown.Option value="/catalog">
          <div className={cx(styles.aligned)}>
            <Person fontSize="large" className={cx(styles.icon)} />
            <span>{t('layouts.navigation.participant')}</span>
          </div>
        </Dropdown.Option>
      )}
    </Dropdown>
  );
};
