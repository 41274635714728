import { pick } from 'Utils/pick';
import {
  IFormReducerState,
  IFormValidationResult,
  TField,
  TFormValidatorOptions,
  TSavedField,
} from '../Types';
import { getFormValues } from './getFormValues';

export function getFieldWithEvaluation(
  state: IFormReducerState,
  field: TField,
  evaluation: IFormValidationResult,
  properties?: (keyof TField)[]
) {
  return {
    ...(properties ? pick<TField>(field, ...properties) : field),
    ...{
      ...(evaluation as IFormValidationResult & { messages: string[] }),
      ...('messages' in evaluation && evaluation.messages
        ? {
            messages: state.translator(
              evaluation.messages.filter(
                (message) => message.message && message.valid === false
              ),
              state.translations[state.language]
            ),
          }
        : {}),
    },
  };
}

/**
 * getValidatedFormState.
 *
 * Returns the `values` property and adds validation properties based on the validator to the fields within the state.
 */
export function getValidatedFormState(
  state: IFormReducerState,
  context: TFormValidatorOptions = {},
  properties?: (keyof TField)[]
) {
  const values = getFormValues(state.fields);
  return {
    values,
    fields: state.fields.reduce<TSavedField[]>((fields, field) => {
      const evaluation = state.validator(field, values[field.name], context);
      return [
        ...fields,
        getFieldWithEvaluation(state, field, evaluation, properties),
      ];
    }, []),
  };
}
