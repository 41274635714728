import { useIndexCRUD } from '../Factories/useIndexCRUD';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  Notification,
  NotificationBulkUpdate,
  NotificationCreate,
  NotificationFilters,
  NotificationIncludes,
  NotificationSort,
  NotificationUpdate,
} from '../Types/Resources/Notification';
import { HookParams } from '../Types/Utils/HookParams';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

interface Props
  extends HookParams<
    typeof NotificationIncludes,
    NotificationFilters,
    typeof NotificationSort
  > {
  account?: UUID;
  notification?: UUID;
}

export function useNotification(
  { account, notification, ...queryParameters }: Props = {},
  settings?: IUseQueryOptions<DataDocument<Notification>, ErrorDocument>
) {
  const {
    parameters: { account: defaultAccount, notification: defaultNotification },
  } = useApi();
  const request = new ApiRequest({
    enabled:
      !!(account || defaultAccount) && !!(notification || defaultNotification),
    baseName: 'v1.notifications',
    baseUri: `/v1/accounts/${account || defaultAccount}/notifications`,
    uri: `/${notification || defaultNotification}`,
    invalidate: ['notification', 'notifications'],
    queryParameters,
  });
  return useSingleCRUD<
    Notification,
    NotificationCreate,
    Partial<NotificationUpdate>
  >(request, settings);
}

export function useNotifications(
  { account, ...queryParameters }: Props = {},
  settings?: IUseQueryOptions<DataDocument<Notification[]>, ErrorDocument>
) {
  const {
    parameters: { account: defaultAccount },
  } = useApi();
  const request = new ApiRequest({
    enabled: !!(account || defaultAccount),
    baseName: 'v1.notifications',
    baseUri: `/v1/accounts/${account || defaultAccount}/notifications`,
    invalidate: ['notification', 'notifications'],
    queryParameters,
  });
  return useIndexCRUD<Notification, NotificationCreate, NotificationBulkUpdate>(
    request,
    settings
  );
}
