import { useShow } from '../Factories/useShow';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { DateTimeString } from '../Types/Utils/DateTimeString';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { IResource } from '../Types/Utils/Resource';

interface IPing extends IResource<'ping'> {
  status: 'online' | 'offline' | 'error';
  timestamp: DateTimeString;
  redirect?: string;
}

export function usePing(
  settings: IUseQueryOptions<DataDocument<IPing>, ErrorDocument> = {}
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'ping',
      baseUri: '/ping',
    },
    context
  );
  return useShow<IPing>(request, settings);
}
