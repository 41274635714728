import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import queryClient from '../Providers/ApiProvider/QueryClient';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IResource } from '../Types/Utils/Resource';

export function useCreate<
  Resource extends IResource | undefined,
  CreateInput,
  ErrorOutput extends ErrorDocument = ErrorDocument,
>(
  request: ApiRequest,
  queryConfig?: UseMutationOptions<
    DataDocument<Resource>,
    ErrorOutput,
    CreateInput,
    any
  >
) {
  return useMutation<DataDocument<Resource>, ErrorOutput, CreateInput, any>({
    mutationFn: request.create.bind(request),
    mutationKey: request.getKey('create') || '',
    onSuccess: () =>
      Promise.all<void>([
        queryClient.invalidateQueries({
          queryKey: [{ [request.baseName]: true }],
        }),
        ...request.invalidate.map((invalidKey) =>
          queryClient.invalidateQueries({
            queryKey: [{ [invalidKey]: true }],
          })
        ),
      ]).then(),
    ...queryConfig,
  });
}
