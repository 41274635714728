import React, { PropsWithChildren } from 'react';
import { useFormField } from '@brainstud/universal-components/Components/Form/useFormField';
import classNames from 'classnames/bind';
import { v4 } from 'uuid';
import { CheckboxProps } from './CheckboxProps';
import styles from './Checkbox.module.css';

const cx = classNames.bind(styles);

/**
 * Renders an input type checkbox based on a label and with both valid as invalid styling capabilities for multiple
 * choice questions. Use the `quiet` attribute to render a simple checkbox with text.
 */
export const Checkbox = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<CheckboxProps>
>(
  (
    {
      id,
      name,
      defaultValue,
      rules,
      quiet,
      small,
      label,
      prefix,
      disabled,
      checked: isChecked,
      defaultChecked = false,
      valid: defaultValid,
      onClick,
      onMouseOver,
      indeterminate,
      onFocus,
      onChange,
      onReset,
      onSubmit,
      onContextMenu,
      readOnly,
      className,
      style,
      children,
    },
    ref
  ) => {
    const identifier =
      typeof id === 'number'
        ? `${name}_${id}`
        : id || name || v4().replace('-', '_');

    const { checked, field, setChecked, valid, locked } = useFormField({
      id: name ? String(identifier) : undefined,
      name,
      checked: isChecked,
      defaultChecked,
      defaultValue,
      rules,
    });
    const { messages } = field || {};

    const validity = valid === undefined ? defaultValid : valid;
    return (
      <div
        className={cx(
          styles.base,
          'ui-checkbox__base',
          {
            'has-errors': (messages || []).length > 0,
            'is-valid': validity === true,
            'is-invalid': validity === false,
            'without-label': !label && !children,
            disabled,
            quiet,
          },
          className
        )}
        style={style}
      >
        <input
          className={cx(styles.input)}
          type="checkbox"
          id={identifier}
          name={name}
          ref={ref}
          defaultValue={defaultValue}
          checked={checked}
          disabled={locked || disabled}
          onChange={(event) => {
            onChange?.(event);
            if (isChecked === undefined) {
              setChecked(event.currentTarget.checked);
            }
          }}
          onReset={onReset}
          readOnly={readOnly}
          onSubmit={onSubmit}
        />

        <div className={cx(styles.inner, 'ui-checkbox__inner', { small })}>
          <div
            className={cx(styles.square, 'ui-checkbox__square', {
              indeterminate,
            })}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              {indeterminate && (
                <line
                  className={cx('square-line')}
                  x1="4"
                  y1="8"
                  x2="12"
                  y2="8"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              )}
              <path
                fill="currentColor"
                className={cx('square-checkmark')}
                d="M3.2,7.69L4.21,6.68L6.363,8.833L11.796,3.4L12.8,4.41L6.363,10.847L3.2,7.69Z"
              />
            </svg>
          </div>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
          <label
            htmlFor={identifier}
            className={cx(styles.label)}
            onClick={onClick}
            onContextMenu={onContextMenu}
            onMouseOver={onMouseOver}
            onFocus={onFocus}
          >
            {prefix && (
              <span
                className={cx(
                  styles.prefix,
                  'ui-checkbox__prefix',
                  'checkbox-prefix'
                )}
              >
                {prefix}
              </span>
            )}
            {label || children}
          </label>
        </div>
      </div>
    );
  }
);
