import { useMemo } from 'react';
import { QueryObserverResult, UseMutationResult } from '@tanstack/react-query';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { IResource } from '../Types/Utils/Resource';
import { useCreate } from './useCreate';
import { useIndex } from './useIndex';

type TOutputFormat<Resource extends IResource, CreateInput> = [
  {
    data: Resource[];
    create: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput
    >;
  },
  QueryObserverResult<DataDocument<Resource | Resource[]>, ErrorDocument>,
];

export function useIndexCR<Resource extends IResource, CreateInput>(
  request: ApiRequest,
  settings?: IUseQueryOptions<DataDocument<Resource[]>, ErrorDocument>
): TOutputFormat<Resource, CreateInput> {
  const [data, responseIndex] = useIndex<Resource>(request, settings);

  const create = useCreate<Resource, CreateInput>(request);

  return useMemo(
    () => [
      {
        data,
        create,
      },
      responseIndex,
    ],
    [data, create, responseIndex]
  );
}
