import { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useUserData } from 'Hooks/UserData';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './Avatar.module.css';

const cx = classNames.bind(styles);

export type Props = {
  /** The number of letters to show, defaults to 1 */
  length?: number;
  /** Custom children to render  */
  children?: ReactNode;
  /** Custom className to be attached to the avatar */
  className?: string;
  /** Predefined name to create the avatar for */
  name?: string;
  /** The size of the avatar */
  size?: 'extra-small' | 'small' | 'medium' | 'large';
  /** Image url */
  url?: string;
  /** Use the 'outline' styling, which shows the avatar with a small border */
  outline?: boolean;
  /** Use the 'quiet' styling, which shows the avatar without a small border but identical to the outline styling */
  quiet?: boolean;
  /** Date time string which indicates when the user was last seen online */
  lastOnline?: string;
};

/**
 * Avatar.
 *
 * Shows the users' avatar. Defaults to letters with a background.
 */
export const Avatar = ({
  length = 1,
  size = 'small',
  url,
  className,
  children,
  name,
  outline = false,
  quiet = false,
  lastOnline = '',
}: Props) => {
  const [{ fullName }, { isLoading }] = useUserData();
  const avatarUrl = url ? `${url}?width=150` : undefined;
  const isOnline =
    lastOnline &&
    DateFormatter.differenceIn('minutes', new Date(), new Date(lastOnline)) <=
      15;
  const accountLetters = isLoading ? null : (
    <span className={cx(styles.initials)}>
      {(name || fullName)?.slice(0, length) || '?'}
    </span>
  );

  const letters = typeof children !== 'undefined' ? children : accountLetters;

  return (
    <div className={cx(styles.base)}>
      <div className={cx(styles.avatar, size, className, { outline, quiet })}>
        {avatarUrl ? <img alt={fullName} src={avatarUrl} /> : letters}
      </div>
      {isOnline && <div className={cx(styles.isOnline)} />}
    </div>
  );
};
