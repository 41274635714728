import { useEffect } from 'react';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import { TErrorViewProps } from './Types';

type Props = {
  title?: string;
  description?: string;
};

const ErrorModal = ({ title, description }: Props) => {
  const [t] = useTranslator();
  return (
    <ContentModal>
      {title && <h2>{t(title)}</h2>}
      {description && <p>{t(description)}</p>}
    </ContentModal>
  );
};

/**
 * ModalExceptionTemplate.
 *
 * Opens a modal displaying the error with title and description.
 */
export const ModalExceptionTemplate = ({
  title,
  description,
}: Omit<TErrorViewProps, 'handleReset'>) => {
  const { openModal } = useModals();

  useEffect(() => {
    if (title) {
      openModal(ErrorModal, { title, description });
    }
  }, [openModal, title, description]);

  return null;
};
