import { useMemo } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Account } from '@brainstud/academy-api/Types/Resources/Account';

/**
 * useUserData.
 *
 * Retrieves account data from me route for easy access.
 */
export function useUserData() {
  const [me, { isLoading }] = useMe();

  const account = useMemo(
    () => me?.account?.() || ({} as Partial<Account>),
    [me]
  );
  /**
   * Use this instead of the real email address of the user to promote anonymity.
   * The user can be derived from the account ID and the entity the user is associated with.
   */
  const fakeAccountEmail = useMemo(
    () =>
      account.id && account.entity?.()?.name
        ? `${account.id}@${account.entity().name}.fake`
        : '',
    [account]
  );

  return [
    {
      ...account,
      fakeAccountEmail,
      portal: me?.portal?.(),
      isAuthenticated: !!me?.account,
    },
    { isLoading },
  ] as const;
}
