import React, { useMemo, useRef, useState } from 'react';
import { useAccount } from '@brainstud/academy-api/Hooks/useAccounts';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { useNotifications } from '@brainstud/academy-api/Hooks/useNotifications';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Badge } from '@brainstud/ui/Static/Badge';
import { Notifications } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Avatar } from 'Components/Avatar';
import { Link } from 'Components/Link';
import { ZendeskButton } from 'Components/ZendeskButton';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { useThemeProvider } from 'Providers/ThemeProvider/useThemeProvider';
import { useTranslator } from 'Providers/Translator';
import { PARTIAL_NOTIFICATIONS_LIMIT } from 'Views/NotificationsView';
import { ReactComponent as LogoSVG } from '../../Assets/logo.svg';
import { Breadcrumbs } from '../Breadcrumbs';
import { Navigation } from '../Navigation';
import { AccountSubmenu } from './Components/AccountSubmenu';
import { HeaderNotifications } from './Components/HeaderNotifications';
import styles from './Header.module.css';

const cx = classNames.bind(styles);

type HeaderProps = {
  className?: string;
  style?: React.CSSProperties;
};

/**
 * Header.
 *
 * The application header component. Loaded in almost every page.
 */
export const Header = ({ className, style }: HeaderProps) => {
  const [t] = useTranslator();
  const [, { isLoading }] = useMe();
  const [menu, setMenu] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const notificationButton = useRef<HTMLButtonElement | HTMLAnchorElement>(
    null
  );
  const avatarButton = useRef<HTMLButtonElement>(null);
  const [{ data: account }] = useAccount();
  const [, { data: request }] = useNotifications(
    { limit: PARTIAL_NOTIFICATIONS_LIMIT, filter: { status: 'unread' } },
    { refetchInterval: 300000 } // Every 5 minutes
  );

  const unreadNotificationsCount = useMemo(
    () => request?.meta?.total || 0,
    [request]
  );

  const { isGuestEnvironment } = useEnvironmentProvider();
  const isGuest = useHasAnyRole('unauthenticated');

  const { assets } = useThemeProvider();
  const logo = assets?.logo;

  return (
    <>
      <header className={cx(styles.base, className)} style={style}>
        {!isGuest && <Navigation />}
        <Link href="/" active={false} className={styles['logo-link']}>
          {!isLoading &&
            (logo ? (
              // eslint-disable-next-line @next/next/no-img-element
              <img src={logo} alt="" className={styles['logo-image']} />
            ) : (
              <LogoSVG className={styles['logo-image']} />
            ))}
        </Link>

        <div className={styles['right-side']}>
          {!isLoading && (
            <>
              {isGuest ? (
                <Button to="/auth" className={styles['cta-button']}>
                  {t('layouts.header.login')}
                </Button>
              ) : (
                <>
                  <div className={styles.notifications}>
                    <div className={cx(styles.notificationButtonWrapper)}>
                      <ZendeskButton className={cx(styles.button)} />
                    </div>
                    <div
                      className={cx(
                        styles.notificationButtonWrapper,
                        styles.mobile
                      )}
                    >
                      <Button
                        type="button"
                        className={cx(styles.button)}
                        small
                        round
                        quiet
                        ref={notificationButton}
                        onClick={() =>
                          setNotificationMenu((prevOpen) => !prevOpen)
                        }
                      >
                        <Notifications fontSize="large" />
                        {parseInt(`${unreadNotificationsCount}`, 10) > 0 && (
                          <Badge
                            color="red"
                            circle
                            className={cx(styles.indicator)}
                          >
                            {unreadNotificationsCount}
                          </Badge>
                        )}
                      </Button>
                    </div>
                    <HeaderNotifications
                      opener={notificationButton}
                      open={notificationMenu}
                      onClose={() => setNotificationMenu(false)}
                    />
                  </div>
                  <button
                    type="button"
                    ref={avatarButton}
                    className="no-outline onboarding-menu-2"
                    onClick={() => setMenu((open) => !open)}
                  >
                    <Avatar
                      quiet
                      outline
                      name={account?.fullName}
                      url={account?.avatarUrl}
                      className={cx(styles.avatar)}
                    />
                  </button>
                  <AccountSubmenu
                    open={menu}
                    opener={avatarButton}
                    onClose={() => setMenu(false)}
                  />
                </>
              )}
            </>
          )}
        </div>
      </header>
      {!isGuestEnvironment && <Breadcrumbs />}
    </>
  );
};
