import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@brainstud/ui/Buttons/Button';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown';
import { DropdownOption } from '@brainstud/ui/Form Input/Dropdown/Option';
import { ContentModal } from '@brainstud/ui/Modals/ContentModal';
import { Check, Close, Delete } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { STAGING_ENDPOINTS } from '../../Config/development';
import styles from './DebugModal.module.css';

type Props = {
  closeModal: () => void;
};

type TApiResponse = {
  portal_domain?: string;
  api_endpoint?: string;
};

/**
 * DebugModal.
 *
 * Shows a set of options to change during review.
 */
export const DebugModal = ({ closeModal }: Props) => {
  const [apiEndpoint, setApiEndpoint] = useState<string>();

  useEffect(() => {
    const runtimeVarsUrl = `${window.location.origin}/api/auth/runtime-vars`;
    axios
      .get<TApiResponse>(runtimeVarsUrl, {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((result) => {
        setApiEndpoint(result.data.api_endpoint);
      });
  }, []);

  const handleEndpointChange = useCallback((value?: string) => {
    setApiEndpoint(value);
    return axios.post(
      `${window.location.origin}/api/auth/runtime-vars`,
      {
        api_endpoint: value,
      },
      { headers: { 'Content-Type': 'application/json' } }
    );
  }, []);

  const queryClient = useQueryClient();
  const handleReset = useCallback(async () => {
    await axios.delete(`${window.location.origin}/api/auth/runtime-vars`, {
      headers: { 'Content-Type': 'application/json' },
    });
    await queryClient.invalidateQueries();
    closeModal();
  }, [queryClient, closeModal]);

  const handleSave = useCallback(async () => {
    await queryClient.invalidateQueries();
    closeModal();
  }, [queryClient, closeModal]);

  const [errorThrow, setErrorThrow] = useState(false);
  if (errorThrow) {
    throw new Error('This is a test error.');
  }

  return (
    <ContentModal
      closeable
      onClose={closeModal}
      onClickOutside
      style={{ overflow: 'visible' }}
    >
      <ContentModal.Header style={{ paddingBottom: 0 }}>
        <div className={styles.heading}>
          <h1>Debug tools</h1>
        </div>
      </ContentModal.Header>

      <dl className={styles.list}>
        <dt>API Endpoint</dt>
        <dd>
          <Dropdown block value={apiEndpoint} onChange={handleEndpointChange}>
            <Dropdown.Option key="default" value="default">
              Default
            </Dropdown.Option>
            {STAGING_ENDPOINTS.map((endpoint) => (
              <DropdownOption key={endpoint} value={endpoint}>
                {endpoint}
              </DropdownOption>
            ))}
          </Dropdown>
        </dd>
        <dt>NEXT_PUBLIC_APP_ENV</dt>
        <dd>{process.env.NEXT_PUBLIC_APP_ENV}</dd>
      </dl>

      <Button type="button" error onClick={() => setErrorThrow(true)}>
        Trigger error
      </Button>

      <ContentModal.Footer>
        <Button type="button" onClick={handleSave}>
          <Check />
          <span>Reload & Close</span>
        </Button>

        <Button type="button" outline onClick={handleReset}>
          <Delete />
          <span>Reset</span>
        </Button>

        <Button type="button" quiet onClick={() => closeModal()}>
          <Close />
          <span>Close</span>
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
