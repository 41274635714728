import React from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { ReactComponent as TargetBlank } from 'Assets/Icons/target-blank.svg';
import { useTranslator } from 'Providers/Translator';
import { OnboardingTooltip } from '../OnboardingTooltip';
import type { IOnboardingFeature } from './Types';

const TooltipOne = () => {
  const [t] = useTranslator();
  const [me] = useMe();
  const portal = me?.portal?.();

  return (
    <OnboardingTooltip arrow="left">
      <p>{t('providers.onboarding.menu.content')}</p>
      {portal?.information.knowledgeBaseUrl && (
        <a
          className="link"
          href={portal?.information.knowledgeBaseUrl}
          target="_blank"
          rel="noreferrer"
        >
          <span>{t('providers.onboarding.menu.button')}</span>
          <TargetBlank />
        </a>
      )}
    </OnboardingTooltip>
  );
};

export const MainMenuFeature: IOnboardingFeature = {
  id: 'main-menu',
  steps: [
    {
      selector: '.onboarding-menu-1',
      position: 'right' as const,
      content: <TooltipOne />,
    },
  ],
};
