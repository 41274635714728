import React, { ComponentPropsWithoutRef, FC } from 'react';
import classNames from 'classnames/bind';
import styles from './Tabs.module.css';

type TabsProps = {
  children: React.ReactNode;
  className?: string;
  dense?: boolean;
  border?: boolean;
  vertical?: boolean;
  style?: React.CSSProperties;
};

const cx = classNames.bind(styles);

/**
 * Tabs.
 *
 * Create a list of tabs to toggle certain content
 * Example usage:
 * ```
 * <Tabs>
 *   <TabsItem onClick={(event) => setActive('first')}>First item</TabsItem>
 *   <TabsItem label="Second item" active />
 *   <TabsItem component={NavLink} to="/url">Third item</TabsItem>
 * </Tabs>
 * ```
 */
const Tabs = ({
  children,
  className,
  dense,
  border = false,
  style,
  vertical = false,
}: TabsProps) => (
  <div
    role="navigation"
    className={cx('root', 'no-print', { dense, border, vertical }, className)}
    style={style}
  >
    {children}
  </div>
);
interface ITabsItem {
  active?: boolean;
  children?: React.ReactNode;
  label?: string;
  pullRight?: boolean;
  className?: string;
  disabled?: boolean;
}

type TabsItemProps<El extends FC<any> | React.ElementType> = {
  component?: El;
} & ComponentPropsWithoutRef<El> &
  ITabsItem;

const TabsItem = <D extends FC<any> | React.ElementType>({
  active,
  component,
  label,
  pullRight,
  disabled,
  className,
  href,
  children,
  ...props
}: TabsItemProps<D>) => {
  const El = disabled ? 'div' : component ?? 'button';
  return (
    <El
      className={cx(styles.item, { active, pullRight, disabled }, className)}
      type={El === 'button' ? 'button' : undefined}
      href={disabled ? undefined : href}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {label ? <span>{label}</span> : children}
    </El>
  );
};

export { TabsItem };
export default Tabs;
