import { FunctionComponent, useCallback, useEffect } from 'react';
import { useModals } from 'Providers/ModalProvider/useModals';

/**
 * useModalOnHash.
 *
 * Used to open a modal from a given target hash and component when the location hash matches.
 */
export function useModalOnHash(
  targetHash: string,
  modalComponent: FunctionComponent
) {
  const { openModal } = useModals();

  /**
   * Clear the hash from the URL, so we always have a clean URL state.
   */
  const clearRouteHash = useCallback(() => {
    window.history.replaceState('', document.title, window.location.pathname);
  }, []);

  const handleHashChange = useCallback(() => {
    const hash = window.location.hash.substring(1);
    if (targetHash === hash) {
      openModal(modalComponent);
      clearRouteHash();
    }
  }, [targetHash, clearRouteHash, modalComponent, openModal]);

  useEffect(() => {
    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [handleHashChange]);
}
