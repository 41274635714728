/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { Panel } from '@brainstud/ui/Static/Panel';
import { Container } from 'Components/Container';
import { Exception } from '../../Exceptions/Exception';
import { BlockExceptionTemplate } from './Templates/BlockExceptionTemplate';
import { ModalExceptionTemplate } from './Templates/ModalExceptionTemplate';
import { ToastExceptionTemplate } from './Templates/ToastExceptionTemplate';

export interface IErrorFallbackProps {
  error: Error;
  /** Adds a container and panel around block template errors */
  container?: boolean;
  handleReset: () => void;
  className?: string;
  children?: ReactNode;
}

/**
 * ErrorFallback.
 *
 * Shown in case of an error.
 */
export const ErrorFallback = ({
  error,
  handleReset,
  children,
  container,
  className,
}: IErrorFallbackProps) => {
  const template = error instanceof Exception ? error.template : 'panel';
  const defaultProps = {
    title: error.name,
    description: error.message,
  };
  const props =
    error instanceof Exception ? error.props || defaultProps : defaultProps;

  if (error instanceof Exception && error.view) {
    const ErrorView = error.view;
    return <ErrorView error={error}>{children}</ErrorView>;
  }

  switch (template) {
    case 'modal': {
      return (
        <>
          <ModalExceptionTemplate {...props} />
          {children}
        </>
      );
    }
    case 'toast': {
      return (
        <>
          <ToastExceptionTemplate {...props} />
          {children}
        </>
      );
    }
    default: {
      return container ? (
        <Container style={{ marginTop: '3rem' }}>
          <Panel pad>
            <BlockExceptionTemplate
              className={className}
              {...props}
              handleReset={handleReset}
            />
          </Panel>
        </Container>
      ) : (
        <BlockExceptionTemplate
          className={className}
          {...props}
          handleReset={handleReset}
        />
      );
    }
  }
};
