import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import * as Sentry from '@sentry/nextjs';
import { useUserData } from 'Hooks/UserData';
import { ErrorDataContext } from './ErrorDataContext';
import { ErrorDataReducer } from './ErrorDataReducer';

type Props = {
  children: ReactNode;
};

/**
 * ErrorDataProvider.
 *
 * Provides methods and application state for better error handling.
 */
export const ErrorDataProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(ErrorDataReducer, {});

  const [{ id: accountId }] = useUserData();

  const handleErrorEvent = useCallback((event: ErrorEvent) => {
    const { error } = event;
    error?.toSentry?.();
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleErrorEvent);
    return () => window.removeEventListener('error', handleErrorEvent);
  }, [handleErrorEvent]);

  useEffect(() => {
    if (accountId) {
      Sentry.setUser({
        id: accountId,
      });
    }
  }, [accountId]);

  const { parameters } = useApi();
  useEffect(() => {
    if (parameters) {
      Sentry.setExtra('Default Route Parameters', parameters);
    }
  }, [parameters]);

  const { extra } = state;
  useEffect(() => {
    if (extra) {
      Object.keys(extra).forEach((key) => {
        Sentry.setExtra(key, extra[key] as { [key: string]: any });
      });
    }
  }, [extra]);

  const context = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <ErrorDataContext.Provider value={context}>
      {children}
    </ErrorDataContext.Provider>
  );
};
