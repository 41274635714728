import React, { ReactElement, ReactNode, Suspense } from 'react';
import ApiProvider from '@brainstud/academy-api/Providers/ApiProvider/ApiProvider';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Loading } from 'Components/Loading';
import FullScreenLoader from 'Components/Loading/FullScreenLoader';
import Base from 'Layouts/Base/Base';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import PlausibleProvider from 'next-plausible';
import { AccessProvider } from 'Providers/AccessProvider';
import { BreadcrumbsProvider } from 'Providers/BreadcrumbsProvider';
import { EnvironmentProvider } from 'Providers/EnvironmentProvider';
import { ErrorDataProvider } from 'Providers/ErrorDataProvider';
import { HeadProvider } from 'Providers/HeadProvider';
import { ModalProvider } from 'Providers/ModalProvider';
import { ModalSwitch } from 'Providers/ModalProvider/ModalSwitch';
import { OnboardingProvider } from 'Providers/OnboardingProvider';
import { SystemEventProvider } from 'Providers/SystemEventProvider';
import { ThemeProvider } from 'Providers/ThemeProvider';
import { ToasterProvider } from 'Providers/ToasterProvider';
import { TranslatorProvider } from 'Providers/Translator';
import { ViewSettingsProvider } from 'Providers/ViewSettingsProvider';
import { isEmpty } from 'Utils/isEmpty';
import { DebugPanel } from '../Components/DebugPanel';
import { ErrorBoundary } from '../Providers/ErrorDataProvider/ErrorBoundary';
import '../styles/styles.css';

type TPossiblePageProps = Partial<{
  dark: boolean;
  headless: boolean;
}>;

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<TPossiblePageProps> & {
  Component: NextPageWithLayout<TPossiblePageProps>;
};

// Why did you render, development performance tracking
if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_DID_IT_RENDER === 'true'
) {
  // eslint-disable-next-line no-console
  console.debug(
    'Applying whyDidYouRender, to help you locate unnecessary re-renders during development. See https://github.com/welldone-software/why-did-you-render'
  );
  // See https://github.com/welldone-software/why-did-you-render#options
  whyDidYouRender(React, {
    include: [],
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    logOwnerReasons: true,
    collapseGroups: true,
    titleColor: 'lightblue',
    diffNameColor: 'orange',
    diffPathColor: 'green',
  });
}

export default function App({ Component, pageProps = {} }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SystemEventProvider>
      <ApiProvider>
        <Suspense
          fallback={
            <FullScreenLoader>
              <Loading />
            </FullScreenLoader>
          }
        >
          <ErrorBoundary container>
            <TranslatorProvider>
              <ThemeProvider>
                <PlausibleProvider
                  enabled={!isEmpty(process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN)}
                  domain={process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN ?? ''}
                  taggedEvents
                  selfHosted
                >
                  <ViewSettingsProvider>
                    <ModalProvider>
                      <HeadProvider>
                        <EnvironmentProvider>
                          <BreadcrumbsProvider>
                            <ToasterProvider>
                              <OnboardingProvider>
                                <AccessProvider>
                                  <ErrorDataProvider>
                                    <ErrorBoundary container>
                                      <Base
                                        dark={pageProps.dark}
                                        headless={pageProps.headless}
                                      >
                                        {getLayout(
                                          // eslint-disable-next-line react/jsx-props-no-spreading
                                          <Component {...pageProps} />
                                        )}
                                        <ModalSwitch />
                                        {process.env.NEXT_PUBLIC_APP_ENV !==
                                          'production' && <DebugPanel />}
                                      </Base>
                                    </ErrorBoundary>
                                  </ErrorDataProvider>
                                </AccessProvider>
                              </OnboardingProvider>
                            </ToasterProvider>
                          </BreadcrumbsProvider>
                        </EnvironmentProvider>
                      </HeadProvider>
                    </ModalProvider>
                  </ViewSettingsProvider>
                </PlausibleProvider>
              </ThemeProvider>
            </TranslatorProvider>
          </ErrorBoundary>
        </Suspense>
      </ApiProvider>
    </SystemEventProvider>
  );
}
