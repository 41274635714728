import React, { useCallback, useRef } from 'react';
import { useOnClickOutside } from '@brainstud/universal-components/Hooks/useOnClickOutside';
import { PaginationProvider } from '@brainstud/universal-components/Providers/PaginationProvider';
import classNames from 'classnames/bind';
import { NotificationsView } from 'Views/NotificationsView/NotificationsView';
import styles from './HeaderNotifications.module.css';

const cx = classNames.bind(styles);

type Props = {
  open: boolean;
  opener: React.MutableRefObject<null | HTMLButtonElement | HTMLAnchorElement>;
  onClose: Function;
};

export const HeaderNotifications = ({ open, opener, onClose }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useOnClickOutside([opener, ref], handleClose);

  return !open ? null : (
    <PaginationProvider>
      <div className={cx(styles.base)} ref={ref}>
        <NotificationsView partial />
      </div>
    </PaginationProvider>
  );
};
