import { useEffect } from 'react';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { TErrorViewProps } from './Types';

/**
 * ToastExceptionTemplate.
 *
 * Shows an error toast message based on the error title.
 */
export const ToastExceptionTemplate = ({
  title,
}: Omit<TErrorViewProps, 'handleReset'>) => {
  const [setToast] = useToaster();
  const [t] = useTranslator();

  useEffect(() => {
    if (title) {
      setToast(t(title), 'error');
    }
  }, [title, t, setToast]);

  return null;
};
