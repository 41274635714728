import { useCallback, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@brainstud/universal-components/Hooks/useOnClickOutside';
import { UseModalProps } from './UseModalProps';

export function useModal({
  onClose,
  closeModal,
  onKeyUp,
  onClickOutside,
  closeable,
}: UseModalProps) {
  const modalElement = useRef<HTMLDivElement | null>(null);
  const [opening, setOpening] = useState(true);

  const handleClose = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    } else if (typeof closeModal === 'function' && closeable) {
      closeModal();
    }
  }, [onClose, closeModal, closeable]);

  const handleKeyPress = useCallback(
    (e) => {
      if (onKeyUp) {
        onKeyUp(e);
      } else if (e.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose, onKeyUp]
  );

  // Prevents double animation when component re-renders.
  useEffect(() => {
    const timeout = setTimeout(() => setOpening(false), 200);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (onClose || closeable) {
      document.addEventListener('keyup', handleKeyPress);
    }
    return () => {
      if (onClose || closeable) {
        document.removeEventListener('keyup', handleKeyPress);
      }
    };
  }, [onClose, closeable, handleKeyPress]);

  useOnClickOutside([modalElement], () => {
    if (typeof onClickOutside === 'function') {
      onClickOutside();
    } else if (onClickOutside) {
      handleClose();
    }
  });

  return {
    ref: modalElement,
    handleClose,
    animate: opening,
    hasCloseButton: !!(closeable || onClose),
  };
}
