import React, { useCallback, useEffect, useRef } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { Button } from '@brainstud/ui/Buttons/Button';
import { useOnClickOutside } from '@brainstud/universal-components/Hooks/useOnClickOutside';
import { CreditCard, Help, Message, Notifications } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Link } from 'Components/Link';
import { UserBox } from 'Components/UserBox';
import { ADMIN_ENV_ROLES, COACH_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks/Roles/useHasAnyRole';
import { useHandleLogout } from 'Hooks/useHandleLogout';
import { useHistory } from 'Hooks/useHistory';
import { FeedbackModal } from 'Modals/FeedbackModal/FeedbackModal';
import { useModals } from 'Providers/ModalProvider/useModals';
import { useTranslator } from 'Providers/Translator';
import styles from './AccountSubmenu.module.css';

const cx = classNames.bind(styles);

/**
 * AccountSubmenu.
 *
 * Shows the dropdown when clicking on the menu in the header. Provides links
 * to useful sections, such as the account settings.
 */
export const AccountSubmenu = ({
  open,
  opener,
  onClose,
}: {
  open: boolean;
  opener: React.MutableRefObject<any>;
  onClose: () => void;
}) => {
  const [me] = useMe();
  const portal = me?.portal?.();
  const baseElement = useRef(null);
  const [t] = useTranslator();

  const history = useHistory();
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);
  useOnClickOutside([baseElement, opener], handleClose);
  useEffect(
    () =>
      history.listen(() => {
        handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }),
    [history.route, handleClose]
  );

  const hasRoadmapAccess = useHasAnyRole([...ADMIN_ENV_ROLES, ...COACH_ROLES]);

  const { openModal } = useModals();
  const handleOpenFeedbackModal = useCallback(() => {
    openModal(FeedbackModal);
  }, [openModal]);

  const handleLogout = useHandleLogout();

  return !open ? null : (
    <nav className={cx(styles.base)} ref={baseElement}>
      <div className={cx(styles.user)}>
        <UserBox />
      </div>
      <div className={cx(styles.group)}>
        <Link className={styles.link} href="/notifications">
          <Notifications fontSize="large" />
          <span>{t('layouts.account_submenu.notifications')}</span>
        </Link>
        <Link className={styles.link} href="/account/orders">
          <CreditCard fontSize="large" />
          <span>{t('layouts.account_submenu.subscription')}</span>
        </Link>
        {portal?.information.knowledgeBaseUrl && (
          <a
            className={styles.link}
            href={portal?.information.knowledgeBaseUrl}
            target="_blank"
            rel="noreferrer"
          >
            <Help fontSize="large" />
            <span>{t('layouts.account_submenu.help')}</span>
          </a>
        )}
        {hasRoadmapAccess && (
          <button
            type="button"
            className={styles.link}
            onClick={handleOpenFeedbackModal}
          >
            <Message fontSize="large" />
            <span>{t('layouts.account_submenu.give_feedback')}</span>
          </button>
        )}
        <div className={cx(styles.logout)}>
          <Button
            type="button"
            className={cx(styles['logout-button'])}
            block
            onClick={handleLogout}
          >
            <span>{t('layouts.account_submenu.sign_out')}</span>
          </Button>
        </div>
      </div>
    </nav>
  );
};
