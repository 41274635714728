import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { BadgeProps } from './BadgeProps';
import styles from './Badge.module.css';

const cx = classNames.bind(styles);

/**
 * A badge is used to indicate a certain status of another resource, such as a learning object or course.
 */
export const Badge = ({
  color,
  circle,
  size,
  className,
  children,
  width,
}: PropsWithChildren<BadgeProps>) => (
  <div
    className={cx(
      styles.base,
      { circular: circle, 'restrict-width': !!width },
      size,
      color,
      className
    )}
  >
    {children}
  </div>
);
