import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import queryClient from '../Providers/ApiProvider/QueryClient';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IResource } from '../Types/Utils/Resource';
import { UpdateMethods } from '../Types/Utils/UpdateMethods';

export function useUpdate<Resource extends IResource, UpdateInput>(
  request: ApiRequest,
  queryConfig?: UseMutationOptions<
    DataDocument<Resource>,
    ErrorDocument,
    UpdateInput & UpdateMethods,
    any
  >
) {
  return useMutation<
    DataDocument<Resource>,
    ErrorDocument,
    UpdateInput & UpdateMethods
  >({
    mutationKey: request.getKey('update') || [],
    mutationFn: request.update.bind(request),
    onSuccess: () =>
      Promise.all<void>([
        queryClient.invalidateQueries({
          queryKey: [{ [request.baseName]: true }],
        }),
        ...request.invalidate.map((invalidKey) =>
          queryClient.invalidateQueries({ queryKey: [{ [invalidKey]: true }] })
        ),
      ]).then(),
    ...queryConfig,
  });
}
