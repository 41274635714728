import React, { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useModals } from 'Providers/ModalProvider/useModals';
import { DebugModal } from './DebugModal';

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
);

/**
 * DebugPanel.
 *
 * Shows a set of options that can be used during review & development.
 */
export const DebugPanel = () => {
  const { openModal } = useModals();
  const [openDevTools, setOpenDevTools] = useState(false);

  useHotkeys('ctrl + D', () => {
    openModal(DebugModal);
    setOpenDevTools(true);
  });

  if (!openDevTools) {
    return null;
  }

  return <ReactQueryDevtoolsProduction initialIsOpen={false} />;
};
