import { useEffect, useMemo, useState } from 'react';
import { useMe } from '@brainstud/academy-api/Hooks/useMe';
import { useApi } from '@brainstud/academy-api/Providers/ApiProvider/useApi';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers/EnvironmentProvider/useEnvironmentProvider';
import { useTranslator } from 'Providers/Translator';
import { ApiException } from '../Exceptions';
import { useHandleLogout } from './useHandleLogout';

/**
 * useInitialization.
 *
 * Called to set basic data as route parameters, set preferred language,
 * @returns {({me: Me | undefined, isAuthenticated: boolean} | {isLoading: boolean})[]}
 */
export function useInitialization() {
  const { setRouteParameters } = useApi();
  const [me, { isLoading, error }] = useMe({
    suspense: true,
  });
  const router = useRouter();
  const {
    query: { loggedOut },
  } = router as { query: { loggedOut?: string } };
  const account = useMemo(() => me?.account?.(), [me]);
  const portal = useMemo(() => me?.portal?.(), [me]);

  const [, { setLanguage }] = useTranslator();
  useEffect(() => {
    if (me && account && portal) {
      setRouteParameters((prevState) =>
        prevState.account === account.id && prevState.portal === portal.id
          ? prevState
          : {
              account: account.id,
              portal: portal.id,
            }
      );

      if (account.locale) {
        setLanguage(account.locale);
      }
    } else if (portal) {
      setRouteParameters((prevState) =>
        prevState.portal === portal.id
          ? prevState
          : {
              portal: portal.id,
            }
      );
      const defaultLanguage = portal.parameters.find(
        (param) => param.type === 'default_language'
      );
      if (defaultLanguage) {
        setLanguage(defaultLanguage.data);
      }
    }
  }, [me, account, portal, setRouteParameters, setLanguage]);

  const isAuthenticated = !!account?.id;
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);
  const { isGuestEnvironment } = useEnvironmentProvider();
  const handleLogout = useHandleLogout();
  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated && isLoggedIn && !isGuestEnvironment && !loggedOut) {
        handleLogout('logout.timeout', 'warning').then(() => {
          setIsLoggedIn(false);
        });
      }
      if (!isAuthenticated && loggedOut && isLoggedIn) {
        setIsLoggedIn(false);
      }
      if (isAuthenticated && !isLoggedIn) {
        setIsLoggedIn(true);
      }
    }
  }, [
    handleLogout,
    isAuthenticated,
    isLoading,
    isLoggedIn,
    loggedOut,
    isGuestEnvironment,
  ]);

  if (error) {
    throw new ApiException(error);
  }

  return [
    { me, isAuthenticated },
    {
      isLoading,
    },
  ] as const;
}
