import React, { useCallback, useState } from 'react';
import { Callout } from '@brainstud/ui/Static/Callout';
import { Panel } from '@brainstud/ui/Static/Panel';
import { ThumbUp } from '@mui/icons-material';
import axios, { AxiosError } from 'axios';
import classNames from 'classnames/bind';
import { useUserData } from 'Hooks/UserData';
import { TUpvoteArgs } from 'pages/api/feedback/upvote';
import { useToaster } from 'Providers/ToasterProvider/useToaster';
import { useTranslator } from 'Providers/Translator';
import { TProductLaneProject } from '../../pages/api/feedback/projects';
import { FeedbackForm } from './Form';
import styles from './Project.module.css';

const cx = classNames.bind(styles);

type Props = {
  project: TProductLaneProject;
  enableFeedback?: boolean;
};

/**
 * Project
 *
 * Shows a project fetched from productlane.
 */
export const Project = ({ project, enableFeedback = false }: Props) => {
  const [t] = useTranslator();
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [{ fakeAccountEmail }] = useUserData();
  const [setToast] = useToaster();
  const [upvotes, setUpvotes] = useState(project.upvotes);
  const [isUpvoted, setIsUpvoted] = useState(false);

  const handleUpvote = useCallback(async () => {
    if (!fakeAccountEmail) return;

    return axios
      .post<any, any, TUpvoteArgs>('/feedback/upvote', {
        email: fakeAccountEmail,
        projectId: project.id,
      })
      .then(() => {
        setIsOpen(true);
        setUpvotes((prevUpvotes) => prevUpvotes + 1);
        setIsUpvoted(true);
      })
      .catch((err) => {
        const axiosError = err as AxiosError & {
          response: { data: { code?: string } };
        };
        const code = axiosError.response?.data?.code;

        if (code === 'ALREADY_UPVOTED') {
          setToast(
            t('modals.feedback.project.result.already_upvoted'),
            'error'
          );
        } else {
          setError(t('modals.feedback.project.result.error'));
        }
      });
  }, [fakeAccountEmail, project.id, setToast, t]);

  const handleTogglePanel = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <Panel
      className={cx(styles.base, {
        [styles.open]: isOpen,
        [styles.noFeedback]: !enableFeedback,
      })}
    >
      <div className={cx(styles.header)}>
        <button
          onClick={handleTogglePanel}
          type="button"
          className={cx(styles.title)}
        >
          <strong>{project.name}</strong>
        </button>

        {fakeAccountEmail && enableFeedback && (
          <div className={cx(styles.upvotes, { isUpvoted })}>
            <button onClick={handleUpvote} type="button">
              {upvotes > 0 && <span>{upvotes}</span>}
              <ThumbUp />
            </button>
          </div>
        )}
      </div>
      <button
        onClick={handleTogglePanel}
        type="button"
        className={cx(styles.description)}
      >
        <p>{project.description.replace(/#\w{1,}/g, '')}</p>
      </button>
      {error && <Callout error>{error}</Callout>}
      {fakeAccountEmail && enableFeedback && (
        <div className={cx(styles.formContainer)}>
          <div className={cx(styles.form)}>
            <FeedbackForm
              projectId={project.id}
              onCancel={handleTogglePanel}
              label={t('modals.feedback.project.give_feedback')}
            />
          </div>
        </div>
      )}
    </Panel>
  );
};
