/* eslint-disable no-underscore-dangle */
import clone from '../../../Utils/clone';
import { toCamelCase } from '../../../Utils/transformCase';
/* eslint-disable-next-line import/no-cycle */
import DataDocument from '../../Documents/DataDocument/DataDocument';
import { TLinks, TMeta, TResourceObject } from '../../Documents/types';
import Resource from '../Resource';

const _cache = Symbol('cache');

type TCache = {
  [key: string]: Resource | Resource[] | undefined;
};

export default class JsonResource extends Resource {
  public meta?: TMeta;

  public links?: TLinks;

  private [_cache]: TCache = {};

  [key: string]: any;

  constructor(data: TResourceObject<any, any>, document: DataDocument<any>) {
    super(data, document);
    this.meta = data.meta;
    this.links = data.links;

    if (data.attributes) {
      Object.keys(data.attributes).forEach((key) => {
        this[toCamelCase(key)] = document.skipPropertyConversion.includes(key)
          ? data.attributes![key]
          : clone(data.attributes![key], toCamelCase);
      });
    }

    if (data.relationships) {
      const { relationships } = data;
      Object.keys(relationships).forEach((key) => {
        this[key] = () => {
          if (!this[_cache][key]) {
            this[_cache][key] = document.findByResourceLink(
              relationships[key].data
            );
          }
          return this[_cache][key];
        };
      });
    }

    // eslint-disable-next-line no-constructor-return
    return this;
  }
}
