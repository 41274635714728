import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { IResource } from '../Types/Utils/Resource';

type Output<Resource extends IResource> = [
  Resource[],
  QueryObserverResult<DataDocument<Resource[]>, ErrorDocument>,
];

export function useIndex<Resource extends IResource>(
  request: ApiRequest | null,
  queryConfig?: IUseQueryOptions<DataDocument<Resource[]>, ErrorDocument>
): Output<Resource> {
  const response = useQuery<DataDocument<Resource[]>, ErrorDocument>({
    queryKey: request?.getKey('index') || [],
    queryFn: request?.index.bind(request),
    ...queryConfig,
    placeholderData: (prevData) =>
      !!request && request.queryParameters?.page !== undefined
        ? prevData
        : undefined,
    enabled:
      !!request &&
      !!request?.enabled &&
      queryConfig?.enabled !== false &&
      request.isRouteValid(),
  });

  return [response?.data?.data || [], response];
}
