import {
  QueryObserverResult,
  UseMutationResult,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { IResource } from '../Types/Utils/Resource';
import { UpdateMethods } from '../Types/Utils/UpdateMethods';
import { useCreate } from './useCreate';
import { useDestroy } from './useDestroy';
import { useShow } from './useShow';
import { useUpdate } from './useUpdate';

type TOutputFormat<
  Resource extends IResource,
  CreateInput,
  UpdateInput,
  DeleteInput,
> = [
  {
    data: Resource | undefined;
    create: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput
    >;
    update: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      UpdateInput & UpdateMethods
    >;
    createOrUpdate: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput | (UpdateInput & UpdateMethods)
    >;
    destroy: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      DeleteInput
    >;
  },
  (
    | QueryObserverResult<DataDocument<Resource | Resource[]>, ErrorDocument>
    | UseSuspenseQueryResult<DataDocument<Resource | Resource[]>, ErrorDocument>
  ),
];

export function useSingleCRUD<
  Resource extends IResource,
  CreateInput,
  UpdateInput = Partial<CreateInput>,
  DeleteInput = void,
>(
  request: ApiRequest,
  settings?: IUseQueryOptions<DataDocument<Resource>, ErrorDocument>
): TOutputFormat<Resource, CreateInput, UpdateInput, DeleteInput> {
  const [data, responseShow] = useShow<Resource>(request, settings);

  const create = useCreate<Resource, CreateInput>(request);

  const update = useUpdate<Resource, UpdateInput>(request);

  const destroy = useDestroy<Resource, DeleteInput>(request);

  return [
    {
      data,
      create,
      update,
      createOrUpdate: !request.enabled
        ? (create as unknown as UseMutationResult<
            DataDocument<Resource>,
            ErrorDocument,
            CreateInput | (UpdateInput & UpdateMethods)
          >)
        : (update as unknown as UseMutationResult<
            DataDocument<Resource>,
            ErrorDocument,
            CreateInput | (UpdateInput & UpdateMethods)
          >),
      destroy,
    },
    responseShow,
  ];
}
