import axios from 'axios';
import { useIndexCR } from '../Factories/useIndexCR';
import { useSingleCRUD } from '../Factories/useSingleCRUD';
import { THeaders } from '../Providers/ApiProvider/IApiContext';
import { useApi } from '../Providers/ApiProvider/useApi';
import ApiRequest from '../Support/ApiRequest/ApiRequest';
import DataDocument from '../Support/Documents/DataDocument/DataDocument';
import ErrorDocument from '../Support/Documents/ErrorDocument/ErrorDocument';
import {
  PortalLanguage,
  PortalLanguageCreate,
  PortalLanguageUpdate,
} from '../Types/Resources/PortalLanguage';
import { IUseQueryOptions } from '../Types/Utils/IUseQueryOptions';
import { UUID } from '../Types/Utils/UUID';

export async function getPortalLanguages(portal: UUID, headers: THeaders = {}) {
  const domainName = process.env.PORTAL_DOMAIN;
  const result = new DataDocument<PortalLanguage[]>(
    await axios.get(`/v1/portals/${portal}/languages`, {
      headers: {
        ...(domainName ? { 'X-Portal-Domain': domainName } : {}),
        'X-Feature-Next': '1',
        ...headers,
      },
    })
  );

  return [result.data, result] as const;
}

interface IPortalLanguagesProps {
  portal?: UUID;
  language?: UUID;
}

/**
 * Portal languages can be used to set the available languages of a specific portal
 * @param portal
 * @param language
 * @param queryParameters
 * @param settings
 */

export function usePortalLanguage(
  { portal, language, ...queryParameters }: IPortalLanguagesProps = {},
  settings?: IUseQueryOptions<DataDocument<PortalLanguage>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!language,
      baseName: 'v1.portals.language',
      baseUri: `/v1/portals/${portal || defaultPortal}/languages`,
      uri: `/${language}`,
      invalidate: ['v1.portals.languages'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    PortalLanguage,
    PortalLanguageCreate,
    PortalLanguageUpdate
  >(request, settings);
}

/**
 * Portal languages can be used to set the available languages of a specific portal
 * @param portal
 * @param queryParameters
 * @param settings
 */

export function usePortalLanguages(
  { portal, ...queryParameters }: Omit<IPortalLanguagesProps, 'language'> = {},
  settings?: IUseQueryOptions<DataDocument<PortalLanguage[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.languages',
      baseUri: `/v1/portals/${portal || defaultPortal}/languages`,
      invalidate: ['v1.portals.languages'],
      queryParameters,
    },
    context
  );
  return useIndexCR<PortalLanguage, PortalLanguageCreate>(request, settings);
}
