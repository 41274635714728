import { parseDomain, ParseResultListed } from 'parse-domain';

function isStaging(results: ParseResultListed): boolean {
  return (
    (process.env.APP_ENV === 'staging' || process.env.APP_ENV === 'review') &&
    results.domain === 'unlimited' &&
    results.topLevelDomains.includes('software')
  );
}

function parseResultToDomain(results: ParseResultListed): string {
  return [
    ...results.subDomains,
    results.domain,
    ...results.topLevelDomains,
  ].join('.');
}

/**
 * getRootDomain.
 *
 * Retrieves the domain name with top level domain and subdomain and
 * returns that string.
 */
export function getRootDomain(hostName?: string) {
  if (hostName === undefined) {
    return hostName;
  }

  const parsedDomain = parseDomain(hostName.replace(/:[0-9]{1,4}/, ''));

  if (parsedDomain.type === 'LISTED') {
    return isStaging(parsedDomain)
      ? 'stage.unlimited.software'
      : parseResultToDomain(parsedDomain);
  }
  if (parsedDomain.type === 'RESERVED') {
    return 'localhost';
  }
}
